import { FormControl, InputAdornment, TextField } from "@mui/material";

export const AmountTextField = ({
  amount,
  setNewAmount,
  required,
  helperText,
  error,
  disabled,
  size,
  id,
  label,
}) => {
  // Function to format numbers with commas for display
  function formatNumberWithCommas(value) {
    if (value === "-") return "-";
    if (!value) return "";
    let parts = value.split(".");
    let integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.length === 2 ? `${integerPart}.${parts[1]}` : integerPart;
  }

  const handleChangeAmount = (event) => {
    let rawValue = event.target.value.replace(/[,\\-]/g, "");
    const dashCount = event.target.value.split("-").length - 1;

    if (dashCount === 1) {
      rawValue = "-" + rawValue.replace(/-/g, "");
    } else if (dashCount > 1) {
      rawValue = rawValue.replace(/-/g, "");
    }

    if (rawValue === "" || /^-?\d*\.?\d*$/.test(rawValue)) {
      const displayAmount = formatNumberWithCommas(rawValue);
      const intAmount = Math.round(parseFloat(rawValue) * 100); // Convert the amount to cents

      setNewAmount({ displayAmount, intAmount });
    }
  };

  const formatAmount = () => {
    if (
      amount?.displayAmount !== "" &&
      amount?.displayAmount !== null &&
      amount?.displayAmount !== undefined
    ) {
      const numericAmount = parseFloat(
        amount.displayAmount.toString().replace(/,/g, ""),
      ).toFixed(2);
      const displayAmount = formatNumberWithCommas(numericAmount);
      const intAmount = Math.round(parseFloat(numericAmount) * 100); // Convert the amount to cents

      setNewAmount({ displayAmount, intAmount });
    }
  };

  return (
    <FormControl key={`formAmount-${id}-${label}`} fullWidth variant="filled">
      <TextField
        key={`input-${id}-${label}`}
        id={id ? id : "filled-adornment-amount"}
        name={`${label}-${id}`}
        label={label?.length > 0 ? label : "Amount"}
        color="secondary"
        value={amount?.displayAmount || ""}
        onChange={handleChangeAmount}
        slotProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
        variant="outlined"
        onBlur={formatAmount}
        required={required || false}
        helperText={helperText || ""}
        error={error || false}
        disabled={disabled || false}
        size={size}
      />
    </FormControl>
  );
};

export default AmountTextField;
